import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    cohorts: null,
  },
  reducers: {
    setUserCohorts: (state, action) => {
      const { payload } = action;

      state.cohorts = payload;
    },
  },
});
