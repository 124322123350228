import { combineReducers } from 'redux';

import { popupSlice } from './popup';
import { loaderSlice } from './loader';
import { dashboardSlice } from './dashboard';
import { dashboardWorkshopsSlice } from './dashboardWorkshops';
import { openWorkshopSlice } from './openWorkshop';
import { deviceSlice } from './device';
import { learnModeSlice } from './learnMode';
import { authSlice } from './auth';
import { notificationSlice } from './notification';
import { toastSlice } from './toast';
import { userSlice } from './user';

export default combineReducers({
  auth: authSlice.reducer,
  popup: popupSlice.reducer,
  loader: loaderSlice.reducer,
  dashboard: dashboardSlice.reducer,
  dashboardWorkshops: dashboardWorkshopsSlice.reducer,
  openWorkshop: openWorkshopSlice.reducer,
  device: deviceSlice.reducer,
  learnMode: learnModeSlice.reducer,
  notification: notificationSlice.reducer,
  toast: toastSlice.reducer,
  user: userSlice.reducer,
});
